import { AppDetailDTO } from './app-detail-DTO';

export class AppDesignDTO {
  id: Number;

  designName: string;

  appId: Number;

  companyId: number;

  appDetail: string;

  appIdAPI: string;

  isSynchronizedDelivery: boolean;

  appDetailDTO: AppDetailDTO;
  isEdit?: boolean;
  isChecked?: boolean;

  constructor(id?: Number, designName?: string, appId?: Number, companyId?: number, appDetail?: string, appDetailDTO?: AppDetailDTO) {
    this.id = id;
    this.designName = designName;
    this.appId = appId;
    this.companyId = companyId;
    this.appDetail = appDetail;
    this.appDetailDTO = appDetailDTO;
  }
}
