import { SaleTicketDetail } from 'app/model/entity/ticket-editor/set-ticket-detail';

export class SaleTicket {
  /**
   * Sales ticket id
   */
  saleTicketId: number;

  /**
   * Ticket name
   */
  ticketName: any;

  /**
   * App id
   */
  appId: string;

  /**
   * App name
   */
  appName: string;

  /**
   * company Id
   */
  companyId: number;

  /**
   * Ticket name
   */
  companyName: any;

  /**
   * price
   */
  priceAdult: number;
  priceCustom1: number;
  priceCustom2: number;
  priceCustom3: number;

  /**
   Status
   */
  status: number = 1;

  /**
   * Set Ticket Detail
   */
  saleTicketDetail: SaleTicketDetail;
  /**
   * Set Ticket Detail
   */
  saleTicketDetailReserved: SaleTicketDetail;

  isChooseDetailUpdate: boolean;

  isSelected: boolean;
  constructor(
    saleTicketId?: number,
    ticketName?: MultiLanguage,
    appId?: string,
    appName?: string,
    companyId?: number,
    companyName?: MultiLanguage,
    priceAdult?: number,
    priceCustom1?: number,
    priceCustom2?: number,
    priceCustom3?: number,
    status?: number,
    saleTicketDetail?: SaleTicketDetail
  ) {
    this.saleTicketId = saleTicketId;
    this.ticketName = ticketName;
    this.appId = appId;
    (this.appName = appName),
      (this.companyId = companyId),
      (this.companyName = companyName),
      (this.priceAdult = priceAdult),
      (this.priceCustom1 = priceCustom1),
      (this.priceCustom2 = priceCustom2),
      (this.priceCustom3 = priceCustom3),
      (this.status = status);
    this.saleTicketDetail = saleTicketDetail;
  }
}

export interface MultiLanguage {
  ja: string;
  en: string;
}

export interface SalesPrice {
  adult: number;
  custom1: number;
  custom2: number;
  custom3: number;
}
