import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
@Component({
  selector: 'menu-ticket-editor',
  templateUrl: './menu-ticket-editor.component.html',
  styleUrls: ['./menu-ticket-editor.component.scss']
})
export class MenuTicketEditorComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isChoseTabCombination: boolean; // Check chose tab combination
  isFormCombination: boolean; // Check the form processing at the combination tab
  isTabTicket: boolean;
  isTabReservation: boolean; // Check chose tab Reservation
  isTabApplication: boolean; // Check chose tab application
  isEditingSchedule: boolean; // is editing schedule
  isTabSpot: boolean; // Check chose tab Spot
  isTabCrewAccount: boolean; // Check chose tab Spot
  isEditingCrewAccount: boolean; // is editing crew account
  isEditingApplication: boolean; // is editing application
  isCallApiTabSpot: boolean; // is editing application
  isEditingTabSpot: boolean; // is editing crew account
  isUpdateTicket: boolean; // is adding ticket
  isAddingTicket: boolean; // is editing ticket or ticket update
  isEditingTicket: boolean; // is editing ticket or ticket update
  thereArePlansForUpdates: boolean; // Returns true if the entire ticket list is intended to be updated
  isDisableEditSchedule: boolean;
  isCheckWidthMenu: boolean;
  isCheckDelivery: boolean;
  isCheckSetting: boolean;
  isDisplay: boolean;
  constructor(private dataService: DataService, private menuActionService: MenuActionService) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_EDITING_COMBINATION: {
          this.isFormCombination = <boolean>data[1];
          break;
        }
        case Constant.IS_EDIT_SCHEDULE_RESERVATION: {
          this.isEditingSchedule = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_APPLICATION: {
          this.isTabApplication = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_TICKET: {
          this.isTabTicket = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_COMBINATION: {
          this.isChoseTabCombination = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_RESERVATION: {
          this.isTabReservation = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_SPOT: {
          this.isTabSpot = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_CREW_ACCOUNT: {
          this.isTabCrewAccount = <boolean>data[1];
          break;
        }
        case Constant.IS_EDITING_CREW_ACCOUNT: {
          this.isEditingCrewAccount = <boolean>data[1];
          break;
        }
        case Constant.IS_EDITING_APPLICATION: {
          this.isEditingApplication = <boolean>data[1];
          break;
        }
        case Constant.IS_CALL_API_TAB_SPOT: {
          this.isCallApiTabSpot = <boolean>data[1];
          break;
        }
        case Constant.IS_EDITING_SPOT: {
          this.isEditingTabSpot = <boolean>data[1];
          break;
        }
        case Constant.IS_UPDATE_TICKET: {
          this.isUpdateTicket = <boolean>data[1];
          break;
        }
        case Constant.IS_EDITING_TICKET_UPDATTE: {
          this.isEditingTicket = <boolean>data[1];
          break;
        }
        case Constant.THERE_ARE_PLANS_FOR_UPDATES: {
          this.thereArePlansForUpdates = <boolean>data[1];
          break;
        }
        case Constant.IS_DISABLE_EDIT_SCHEDULE: {
          this.isDisableEditSchedule = <boolean>data[1];
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Add Schedule in menu
   * @param {string} moduleName current module name
   */
  addSchedule(moduleName: string) {
    this.menuActionService.addSchedule(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Edit Schedule in menu
   * @param {string} moduleName current module name
   */
  editSchedule(moduleName: string) {
    this.menuActionService.editSchedule(moduleName);
  }

  /**
   * execute action for Edit detail in menu
   * @param {string} moduleName current module name
   */
  editDetail(moduleName: string) {
    this.menuActionService.editDetail(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  delete(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Delete Schedule in menu
   * @param {string} moduleName current module name
   */
  deleteSchedule(moduleName: string) {
    this.menuActionService.deleteSchdule(moduleName);
  }

  /**
   * execute action for translateTicket in menu
   * @param {string} moduleName current module name
   */
  translateTicket(moduleName: string) {
    this.menuActionService.translateTicket(moduleName);
  }

  /**
   * import Reservation
   * @param moduleName
   */
  public importReservation(moduleName: string) {
    this.menuActionService.importReservation(moduleName);
  }

  /**
   * export Reservation
   * @param moduleName
   */
  public exportReservation(moduleName: string) {
    this.menuActionService.exportTimetable(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * delivery
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  addAppDesign(moduleName: string) {
    this.menuActionService.addAppDesign(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  editAppDesign(moduleName: string) {
    this.menuActionService.editAppDesign(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  deleteAppDesign(moduleName: string) {
    this.menuActionService.deleteAppDesign(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  duplicateAppDesign(moduleName: string) {
    this.menuActionService.duplicateAppDesign(moduleName);
  }

  /**
   * glossary
   * @param moduleName
   */
  glossary(moduleName: string) {
    this.menuActionService.glossary(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  addReservation(moduleName: string) {
    this.menuActionService.addReservation(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  editReservation(moduleName: string) {
    this.menuActionService.editReservation(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  deleteReservation(moduleName: string) {
    this.menuActionService.deleteReservation(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  private handlingDisplayMenu(): void {
    this.isCheckWidthMenu = true;
    this.isCheckDelivery = true;
    this.isCheckSetting = true;
    this.isDisplay = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'CheckDelivery') {
        this.isCheckDelivery = <boolean>data[1];
      }
      if (data[0] == 'CheckSetting') {
        this.isCheckSetting = <boolean>data[1];
      }
      if (data[0] == 'Display') {
        this.isDisplay = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDelivery && this.isCheckSetting && this.isDisplay;
    });
  }
}
