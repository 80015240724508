import { AppDesignDTO } from './app-design-DTO';

export class ApplicationDTO {
  /**
   * application id
   */
  id: Number;
  /**
    /**
     * appName
     */
  name: string;

  /**
   * supported Language Translation
   */
  supportedLanguageTranslation: Array<String>;
  /**
   * application design
   */
  appDesigns: Array<AppDesignDTO>;

  /**
   * appId
   */
  appId: string;

  appUrl: string;
  appHomepageUrl: string;
  userPoolId: string;

  isChecked: boolean;
  isEdit: boolean;
  isDuplicate: boolean;
  idAppDuplicate: Number;

  constructor(id?: Number, appName?: string, appDesigns?: Array<AppDesignDTO>, supportedLanguageTranslation: Array<String> = []) {
    this.id = id;
    this.name = appName;
    this.appDesigns = appDesigns;
    this.supportedLanguageTranslation = supportedLanguageTranslation;
  }
}
