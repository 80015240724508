export class AppDetailDTO {
  shortNameOfTheApp: string;

  topPhoto: string;

  topPhotoUrl: string;

  mainColor: string;

  subColor: string;

  backgroundColor: string;

  textColor: string;

  borderColor: string;

  inActiveColor: string;

  imagePath: string;

  idMedia: Number;

  isEdit?: boolean;

  constructor(
    shortNameOfTheApp?: string,
    topPhoto?: string,
    topPhotoUrl: string = '',
    mainColor: string = '#00469A',
    subColor: string = '#545454',
    backgroundColor: string = '#FFFFFF',
    textColor: string = '#000000',
    borderColor: string = '#B4B4B4',
    inActiveColor: string = '#B4B4B4',
    idMedia?: Number
  ) {
    this.shortNameOfTheApp = shortNameOfTheApp;
    this.topPhoto = topPhoto;
    this.topPhotoUrl = topPhotoUrl;
    this.mainColor = mainColor;
    this.subColor = subColor;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.borderColor = borderColor;
    this.inActiveColor = inActiveColor;
    this.idMedia = idMedia;
  }
}
