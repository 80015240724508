import { ApplicationDesignDetail } from './application-design-details';

export class ApplicationDesign {
  /**
   * Application id
   */
  appId: Number;

  /**
   * Application design id
   */
  id: Number;

  /**
   * Design Name
   */
  designName: string;

  // is Edit
  isEdit: boolean;

  // is checked
  isChecked: boolean;

  /**
   * Application Design Detail
   */
  applicationDesignDetail: ApplicationDesignDetail;

  constructor(appId?: Number, id?: Number, designName?: string, applicationDesignDetail?: ApplicationDesignDetail) {
    this.appId = appId;
    this.id = id;
    this.designName = designName;
    this.applicationDesignDetail = applicationDesignDetail;
  }
}
