import { DeviceOperation } from 'app/module/schedule-operation-manager/schedule-operation-manager.component';
import { Media } from '../media';
import { PictureArea } from '../picture-area';
import { TextArea } from '../text-area';

export class DynamicMessageSchedule {
  /**
   * id
   */
  id: Number;
  /**
   * textArea
   */
  textArea: TextArea;
  /**
   * message
   */
  message: string;
  /**
   * device
   */
  device: DeviceOperation;
  /**
   * true if editing
   */
  isEdit: boolean = false;
  /**
   * isChanged
   */
  isChanged: boolean = false;
  /**
   * pictureArea
   */
  pictureArea: PictureArea;
  /**
   * uuidV4 image
   */
  uuidV4Image: any;
  /**
   * media
   */
  media: Media;
  /**
   * media from PC
   */
  mediaFromPC: Media;

  constructor(textArea?: TextArea, message?: string, device?: DeviceOperation) {
    this.textArea = textArea;
    this.message = message;
    this.device = device;
  }
}
